import { defineStore } from 'pinia'
import { ref } from 'vue'
import { ConfigProviderThemeVars } from 'wot-design-uni'
// 变量参考来源
// https://github.com/Moonofweisheng/wot-design-uni/blob/master/src/uni_modules/wot-design-uni/components/common/abstracts/variable.scss

/**
 *  文字字号
 * $-fs-big: var(--wot-fs-big, 24px) !default; // 大型标题
 * $-fs-important: var(--wot-fs-important, 19px) !default; // 重要数据
 * $-fs-title: var(--wot-fs-title, 16px) !default; // 标题字号/重要正文字号
 * $-fs-content: var(--wot-fs-content, 14px) !default; // 普通正文
 * $-fs-secondary: var(--wot-fs-secondary, 12px) !default; // 次要信息，注释/补充/正文
 * $-fs-aid: var(--wot-fs-aid, 10px) !default; // 辅助文字字号，弱化信息，引导性/不可点文字
 */

const BASE_SIZE = 20

const getFontSizeStyle = (
  num: number,
): {
  style: string
  contentFontSize: string
  themeVars: ConfigProviderThemeVars
} => {
  const n = num - 1
  const commonSize = BASE_SIZE + 10 + 2 * n
  //  xs   s    m    l    xl  xxl
  // 10px 12px 14px 16px 19px 24px
  return {
    // 基础的字体大小，就是没有设置css样式时的默认字体大小
    contentFontSize: `font-size: ${commonSize / 2}px`,
    style: `
    --fontsize-xs: ${BASE_SIZE + 0 + 2 * n}rpx;
    --fontsize-s: ${BASE_SIZE + 4 + 2 * n}rpx;
    --fontsize-c: ${BASE_SIZE + 3 + n}rpx;
    --fontsize-m: ${commonSize}rpx;
    font-size: ${commonSize}rpx;
    --fontsize-l: ${BASE_SIZE + 12 + 2 * n}rpx;
    --fontsize-xl: ${BASE_SIZE + 18 + 2 * n}rpx;
    --fontsize-xxl: ${BASE_SIZE + 28 + 2 * n}rpx;
  `,
    themeVars: {
      fsAid: `var(--fontsize-xs)`, // 辅助文字字号
      fsBig: `var(--fontsize-xxl)`, // 大型标题字号
      fsImportant: `var(--fontsize-xl)`, // 重要数据字号
      fsTitle: `var(--fontsize-l)`, // 标题字号/重要正文字号
      fsContent: `var(--fontsize-m)`, // 普通正文字号
      fsSecondary: `var(--fontsize-s)`, // 次要信息字号
      cellTitleFsLarge: `var(--fontsize-l)`, // 普通正文字号
      cellTitleFs: `var(--fontsize-m)`, // 普通正文字号
      navbarTitleFontSize: `var(--fontsize-m)`,
      pickerColumnFs: `var(--fontsize-l)`,
      pickerColumnActiveFs: `var(--fontsize-l)`,
      tableFontSize: `var(--fontsize-m)`,
      messageBoxTitleFs: `var(--fontsize-l)`,
      messageBoxContentFs: `var(--fontsize-m)`,
      radioLabelFs: `var(--fontsize-m)`,
      // tooltipFs: `var(--fontsize-s)`,
    },
  }
}

export const useThemeStore = defineStore(
  'theme',
  () => {
    const fontSizeThemeMultiple = ref(2)
    const displayMode = ref(false)
    const setFontSizeThemeMultiple = (num: number) => {
      fontSizeThemeMultiple.value = num
    }

    const fontSizeStyle = computed(() => {
      return getFontSizeStyle(fontSizeThemeMultiple.value)
    })

    return {
      fontSizeThemeMultiple,
      setFontSizeThemeMultiple,
      fontSizeStyle,
      displayMode,
    }
  },
  {
    persist: true,
  },
)
